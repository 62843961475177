import React from "react";
import "@fontsource-variable/fira-code";
import Component from "./components/Terminal";

function App() {
  return (
    <>
      {/*<Website />*/}
      <Component />
    </>
  );
}

export default App;
