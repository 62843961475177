"use client";

import React, { useState, useEffect, useRef, KeyboardEvent } from "react";
import {
  User,
  Brain,
  Lightbulb,
  Package,
  Users,
  Code,
  Server,
  Database,
  GitBranch,
  Briefcase,
  MessageSquare,
  HelpCircle,
} from "lucide-react";

// Solarized color scheme
const solarized = {
  base03: "#002b36",
  base02: "#073642",
  base01: "#586e75",
  base00: "#657b83",
  base0: "#839496",
  base1: "#93a1a1",
  base2: "#eee8d5",
  base3: "#fdf6e3",
  yellow: "#b58900",
  orange: "#cb4b16",
  red: "#dc322f",
  magenta: "#d33682",
  violet: "#6c71c4",
  blue: "#268bd2",
  cyan: "#2aa198",
  green: "#859900",
};

const getColors = (isDark: boolean) => ({
  background: isDark ? solarized.base03 : solarized.base3,
  foreground: isDark ? solarized.base0 : solarized.base00,
  accent: solarized.yellow,
  highlight: isDark ? solarized.base02 : solarized.base2,
  green: solarized.green,
  blue: solarized.blue,
  cyan: solarized.cyan,
  red: solarized.red,
  gray: isDark ? solarized.base0 : solarized.base00,
  magenta: solarized.magenta,
});

const commands = {
  help: [
    {
      command: "competencies",
      description: "Display Josh's core competencies",
    },
    {
      command: "technologies",
      description: "List technologies Josh is proficient in",
    },
    {
      command: "experience",
      description: "Show Josh's professional experience",
    },
    {
      command: "testimonials",
      description: "Display testimonials from Josh's colleagues",
    },
    { command: "clear", description: "Clear the terminal screen" },
    { command: "light", description: "Switch to light mode" },
    { command: "dark", description: "Switch to dark mode" },
  ],
};

const coreCompetencies = [
  {
    title: "Expert Communicator",
    description:
      "Patient, articulate, comfortable with public speaking, extremely skilled at conveying complex technical information to any audience",
    icon: User,
  },
  {
    title: "Pragmatic Leader",
    description:
      "Strong leadership skills with experience managing engineering teams and setting strategic direction",
    icon: Brain,
  },
  {
    title: "Continuous Learner",
    description: "Up-to-date with the latest industry trends and technologies",
    icon: Lightbulb,
  },
  {
    title: "Product Developer",
    description:
      "Proven track record in the creation and deployment of customer-facing products",
    icon: Package,
  },
  {
    title: "Collaborative",
    description:
      "Demonstrated ability to work cross-functionally with internal and external stakeholders",
    icon: Users,
  },
];

const technologies = [
  {
    category: "Programming Languages",
    items: [
      "Ruby",
      "Python",
      "Java",
      "JavaScript",
      "TypeScript",
      "Rust",
      "Golang",
    ],
    icon: Code,
  },
  {
    category: "Infrastructure",
    items: ["Docker", "Terraform", "Kubernetes", "AWS"],
    icon: Server,
  },
  {
    category: "Datastores",
    items: [
      "DynamoDB",
      "PostgreSQL",
      "Elasticsearch",
      "MongoDB",
      "Redis",
      "ClickHouse",
    ],
    icon: Database,
  },
  {
    category: "Automation",
    items: ["GitHub Actions", "Ansible", "Jenkins"],
    icon: GitBranch,
  },
];

const professionalExperience = [
  {
    title: "Principal Engineer",
    company: "Dirkx Consulting LLC",
    description: [
      "Dirkx Consulting LLC offers engineering excellence on demand.",
      "As a Principal Engineer, I have led a wide range of technology projects from concept to deployment by gathering requirements, driving cross-functional collaboration, and delivering tangible results that align with business objectives.",
      "My expertise is automating processes and optimizing workflows to enhance operational efficiency.",
    ],
  },
  {
    title: "Solutions Architect",
    company: "Release",
    description: [
      "Release is a DevOps SaaS company specializing in environments as a service, offering the automated deployment of infrastructure in a repeatable manner with isolate datasets.",
      "During my tenure as a Solutions Architect, I played a pivotal role in bridging the technical and business worlds by communicating technical information in an easy to digest format for stakeholders. My position required a deep understand of both my companies product and the specific challenges faced by each client, as well as a thorough understanding on Infrastructure as Code, deployment automation strategies, distributed systems, and cloud computing.",
      "Success in this role demanded strong interpersonal skills, as I frequently collaborated with stakeholders to determine requirements tailor solutions towards their specific needs. Using these skills, I was able to drive sales and forge lasting partnerships with our clients.",
    ],
  },
  {
    title: "Senior Software Engineer II",
    company: "TrueCar",
    description: [
      "TrueCar makes car buying easy.",
      "As a Senior Software Engineer II, I provided strategic guidance and mentorship to the software engineering organization on best practices and latest industry trends. My expertise enabled me to act as a subject matter expert over critical revenue generating services.",
      "A few projects I worked on include implementing automation around monitoring business critical applications, architecting and delivering data model re-designs alongside data migrations, and engaging in vendor selection in support of new lines of revenue.",
    ],
  },
];

const testimonials = [
  {
    name: "Chad Rempp",
    title: "Senior Director, Software Engineering",
    content: `Josh was one of the smartest and most adaptable engineering leaders I've had the opportunity to work with. His ability to get up-to speed on complex concepts, balance long-term correctness with speed to market and deliver real results was indispensable on our teams. There are some engineers who "just get it", Josh is one of them.`,
  },
  {
    name: "Seth Battin",
    title: "Principal Software Engineer",
    content: `During our extensive collaboration, I had the distinct pleasure of working with Josh, who served as a consultant on multiple high-stakes projects. The breadth of expertise and commitment Josh brought to each role, be it engineering, management, or architecture, was nothing short of transformative. Josh possesses a rare ability to adapt to diverse challenges and roles, elevating the performance of everyone involved.

What sets Josh apart is not just technical acumen but an unparalleled cooperative spirit. Working alongside Josh, I witnessed firsthand the substantial value and innovative solutions brought to every aspect of our projects. Josh's strategic guidance and meticulous attention to detail were instrumental in navigating complex issues that seemed insurmountable.

I am immensely grateful for the opportunity to have collaborated with Josh and remain hopeful that we will again join forces in future endeavors. I wholeheartedly recommend Josh for any project where skill, integrity, and transformative collaboration are valued. The experience and outcomes from our partnership have profoundly impacted my professional journey, underscoring Josh's exceptional capabilities.`,
  },
];

const CoreCompetencies: React.FC<{ colors: ReturnType<typeof getColors> }> = ({
  colors,
}) => {
  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 gap-4">
        {coreCompetencies.map((competency, index) => (
          <div
            key={index}
            className="border rounded-lg p-4"
            style={{ borderColor: colors.highlight }}
          >
            <div className="flex items-center mb-2">
              <competency.icon
                size={24}
                className="mr-2"
                style={{ color: colors.accent }}
              />
              <h3
                className="text-base sm:text-lg font-semibold"
                style={{ color: colors.accent }}
              >
                {competency.title}
              </h3>
            </div>
            <p className="text-sm sm:text-base">{competency.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const Technologies: React.FC<{ colors: ReturnType<typeof getColors> }> = ({
  colors,
}) => {
  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 gap-4">
        {technologies.map((tech, index) => (
          <div
            key={index}
            className="border rounded-lg p-4"
            style={{ borderColor: colors.highlight }}
          >
            <div className="flex items-center mb-2">
              <tech.icon
                size={24}
                className="mr-2"
                style={{ color: colors.accent }}
              />
              <h3
                className="text-base sm:text-lg font-semibold"
                style={{ color: colors.accent }}
              >
                {tech.category}
              </h3>
            </div>
            <p className="text-sm sm:text-base">{tech.items.join(", ")}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const ProfessionalExperience: React.FC<{
  colors: ReturnType<typeof getColors>;
}> = ({ colors }) => {
  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 gap-8">
        {professionalExperience.map((exp, index) => (
          <div
            key={index}
            className="border rounded-lg p-4"
            style={{ borderColor: colors.highlight }}
          >
            <div className="flex items-center mb-2">
              <Briefcase
                size={24}
                className="mr-2"
                style={{ color: colors.accent }}
              />
              <h3
                className="text-base sm:text-lg font-semibold"
                style={{ color: colors.accent }}
              >
                {exp.title} - {exp.company}
              </h3>
            </div>
            {exp.description.map((paragraph, pIndex) => (
              <p key={pIndex} className="text-sm sm:text-base mb-2">
                {paragraph}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const Testimonials: React.FC<{ colors: ReturnType<typeof getColors> }> = ({
  colors,
}) => {
  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 gap-8">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="border rounded-lg p-4"
            style={{ borderColor: colors.highlight }}
          >
            <div className="flex items-center mb-2">
              <MessageSquare
                size={24}
                className="mr-2"
                style={{ color: colors.accent }}
              />
              <h3
                className="text-base sm:text-lg font-semibold"
                style={{ color: colors.accent }}
              >
                {testimonial.name}
              </h3>
            </div>
            <p
              className="text-sm sm:text-base mb-2 italic"
              style={{ color: colors.blue }}
            >
              {testimonial.title}
            </p>
            <p className="text-sm sm:text-base whitespace-pre-wrap">
              {testimonial.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const Header: React.FC<{ colors: ReturnType<typeof getColors> }> = ({
  colors,
}) => (
  <header className="py-6 sm:py-8 px-4 sm:px-6">
    <div className="max-w-3xl mx-auto text-center">
      <h2 className="text-2xl sm:text-3xl mb-2">
        <span className="font-bold" style={{ color: colors.gray }}>
          Hey there, I'm{" "}
        </span>
        <span className="font-bold" style={{ color: colors.magenta }}>
          Josh
        </span>
      </h2>
      <p className="text-lg sm:text-xl" style={{ color: colors.foreground }}>
        and I use technology to transform businesses
      </p>
    </div>
  </header>
);

const CommandButton: React.FC<{
  command: string;
  onClick: () => void;
  colors: ReturnType<typeof getColors>;
}> = ({ command, onClick, colors }) => (
  <div
    onClick={onClick}
    className="w-full px-3 py-1 sm:px-4 sm:py-2 text-left transition-colors rounded-md hover:bg-opacity-10 text-sm sm:text-base cursor-pointer"
    style={{ backgroundColor: colors.highlight, color: colors.foreground }}
  >
    <span className="font-bold" style={{ color: colors.accent }}>
      {command}
    </span>
  </div>
);

export default function Component() {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState<React.ReactNode[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [showCommands, setShowCommands] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const colors = getColors(isDarkMode);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [output]);

  useEffect(() => {
    document.addEventListener("click", focusInput);
    return () => {
      document.removeEventListener("click", focusInput);
    };
  }, []);

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInput(value);
    if (value.trim()) {
      const matchedCommands = commands.help
        .map((cmd) => cmd.command)
        .filter((cmd) => cmd.startsWith(value.toLowerCase()));
      setSuggestions(matchedCommands);
      setSelectedSuggestionIndex(-1);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setInput(suggestion);
    inputRef.current?.focus();
    setSuggestions([]);
    setSelectedSuggestionIndex(-1);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (suggestions.length > 0) {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setSelectedSuggestionIndex((prev) => (prev + 1) % suggestions.length);
          break;
        case "ArrowUp":
          e.preventDefault();
          setSelectedSuggestionIndex(
            (prev) => (prev - 1 + suggestions.length) % suggestions.length,
          );
          break;
        case "Enter":
          e.preventDefault();
          if (selectedSuggestionIndex !== -1) {
            setInput(suggestions[selectedSuggestionIndex]);
            setSuggestions([]);
            setSelectedSuggestionIndex(-1);
          } else {
            handleSubmit(e);
          }
          break;
        case "Escape":
          setSuggestions([]);
          setSelectedSuggestionIndex(-1);
          break;
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    processCommand(input);
    setInput("");
    setSuggestions([]);
    setSelectedSuggestionIndex(-1);
    inputRef.current?.focus();
  };

  const processCommand = (cmd: string) => {
    const trimmedCmd = cmd.trim().toLowerCase();
    setOutput((prev) => [
      ...prev,
      <div key={prev.length} className="mb-2">
        <span style={{ color: colors.blue }}>❯ </span>
        <span style={{ color: colors.cyan }}>{cmd}</span>
      </div>,
    ]);

    if (trimmedCmd === "clear") {
      setOutput([]);
      return;
    }

    if (trimmedCmd === "light" || trimmedCmd === "dark") {
      const newMode = trimmedCmd === "light" ? false : true;
      setIsDarkMode(newMode);
      setOutput((prev) => [
        ...prev,
        <div key={prev.length} className="mb-2" style={{ color: colors.green }}>
          Switched to {trimmedCmd} mode
        </div>,
      ]);
    } else {
      // Add centered, larger, and bold heading for other commands
      setOutput((prev) => [
        ...prev,
        <h2
          key={`${prev.length}-heading`}
          className="text-xl sm:text-3xl font-bold text-center my-4"
          style={{ color: colors.accent }}
        >
          {trimmedCmd.charAt(0).toUpperCase() + trimmedCmd.slice(1)}
        </h2>,
      ]);

      if (trimmedCmd === "help") {
        setOutput((prev) => [
          ...prev,
          <div key={prev.length} className="grid grid-cols-1 gap-2">
            {commands.help.map((item, index) => (
              <div
                key={index}
                className="flex flex-col sm:flex-row sm:items-center mb-2"
              >
                <span
                  className="w-full sm:w-32 font-bold text-sm sm:text-base mb-1 sm:mb-0"
                  style={{ color: colors.accent }}
                >
                  {item.command}
                </span>
                <span
                  className="text-sm sm:text-base"
                  style={{ color: colors.foreground }}
                >
                  {item.description}
                </span>
              </div>
            ))}
          </div>,
        ]);
      } else if (trimmedCmd === "competencies") {
        setOutput((prev) => [
          ...prev,
          <CoreCompetencies key={prev.length} colors={colors} />,
        ]);
      } else if (trimmedCmd === "technologies") {
        setOutput((prev) => [
          ...prev,
          <Technologies key={prev.length} colors={colors} />,
        ]);
      } else if (trimmedCmd === "experience") {
        setOutput((prev) => [
          ...prev,
          <ProfessionalExperience key={prev.length} colors={colors} />,
        ]);
      } else if (trimmedCmd === "testimonials") {
        setOutput((prev) => [
          ...prev,
          <Testimonials key={prev.length} colors={colors} />,
        ]);
      } else {
        setOutput((prev) => [
          ...prev,
          <div
            key={prev.length}
            className="mb-2 text-sm sm:text-base"
            style={{ color: colors.red }}
          >
            Command not found: {cmd}. Type "help" for available commands.
          </div>,
        ]);
      }
    }
    setShowCommands(false);
  };

  const toggleCommands = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowCommands(!showCommands);
  };

  return (
    <div
      className={`min-h-screen flex flex-col`}
      style={{ backgroundColor: colors.background, color: colors.foreground }}
    >
      <Header colors={colors} />
      <div className="flex-1 p-4 overflow-auto">
        <div className="max-w-3xl mx-auto">
          <div className="mb-4">
            {output.map((line, index) => (
              <React.Fragment key={index}>{line}</React.Fragment>
            ))}
          </div>
          <form onSubmit={handleSubmit} className="flex items-center relative">
            <span style={{ color: colors.blue }}>❯ </span>
            <input
              ref={inputRef}
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="flex-grow bg-transparent border-none outline-none ml-2 text-sm sm:text-base"
              style={{
                color: colors.cyan,
                caretColor: colors.cyan,
              }}
              placeholder="Type a command or click anywhere to start typing"
              aria-label="Enter command"
            />
            {suggestions.length > 0 && (
              <div
                className="absolute left-0 right-0 mt-1 bg-opacity-90 rounded-md shadow-lg"
                style={{ backgroundColor: colors.highlight, top: "100%" }}
              >
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={`px-4 py-2 cursor-pointer text-sm sm:text-base ${index === selectedSuggestionIndex ? "bg-opacity-50" : "hover:bg-opacity-30"}`}
                    style={{
                      color: colors.foreground,
                      backgroundColor:
                        index === selectedSuggestionIndex
                          ? colors.accent
                          : colors.background,
                    }}
                    onMouseDown={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
          </form>
          <div ref={bottomRef} />
        </div>
      </div>
      <div className="fixed bottom-4 right-4 flex items-center">
        <div className="relative">
          {showCommands && (
            <div
              className="absolute bottom-full right-0 mb-2 flex flex-col space-y-2 bg-opacity-90 p-2 rounded-md shadow-lg"
              style={{ backgroundColor: colors.highlight }}
            >
              {commands.help.map((cmd) => (
                <CommandButton
                  key={cmd.command}
                  command={cmd.command}
                  onClick={() => processCommand(cmd.command)}
                  colors={colors}
                />
              ))}
            </div>
          )}
          <div
            onClick={toggleCommands}
            className="p-2 rounded-full transition-colors cursor-pointer"
            style={{ backgroundColor: colors.highlight }}
            aria-label="Toggle help menu"
          >
            <HelpCircle size={24} style={{ color: colors.accent }} />
          </div>
        </div>
      </div>
    </div>
  );
}
